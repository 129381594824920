@import '../node_modules/react-phone-number-input/style.css';

@font-face {
  font-family: 'AvantGardeBkBt';
  src: url('../src/assets/font/AVGARDN_2.TTF') format('truetype');
}



@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/font/HelveticaNeue/HelveticaNeue-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/font/HelveticaNeue/HelveticaNeue.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'HelveticaNeue';
  src: url('./assets/font/HelveticaNeue/HelveticaNeue-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}



html {
  background: white;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#c8edee, #daceec);
  background: -moz-linear-gradient(#c8edee, #daceec); /* FF3.6-15 */
  background: -webkit-linear-gradient(#c8edee, #daceec); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(#c8edee, #daceec); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  min-height: 100vh;

}

.h_n {
  font-family: 'HelveticaNeue', sans-serif;
}

body.modal-open #root {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}
.searchFlex{
  display: flex;
  margin-top: 12px !important;
}
.form-group.searchgroup{
width: 100%;
}
.selectfa{
  /* position: absolute;
  color: #fff;
  top: 29%;
  left: 4%; */
  position: absolute;
    left: 2%;
    top: 28%;
    color: #fff;
}
.selectfa1{
  top: 39%;
}
.form-group.searchParent{
  position: relative;
}
.form-group.searchgroup select {
  width: 96%;
  font-size: 14px;
  padding: 10px 6px;
  border-radius: 6px;
  border: none;
  margin-right: 12px;
  background: #5A7F80;
  color: #fff;
  font-weight: 600;
}


.pad-R0 {
  padding-left: 15px;
  padding-right: 0;
}
.phone-search{
  display: none;
}
.popupscroll{
  overflow-y: auto;
    max-height: 100vh;
}
.pad-L0 {
  padding-right: 15px;
  padding-left: 0;
}
.bg-none{
  background: transparent !important;
}
.roundborder {
  border-radius: 14px;
}
::-webkit-scrollbar {
  width: 14px;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #061E1E;
}
::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
.selectDate .modal-header {
  padding-bottom: 0;
}
.selectDate .modal-body .services-offered , .selectDate .modal-body{
  text-align: center;
}
.my-forms.popupSearch {
  min-width: 100% !important;
}

/* ::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px gray;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  outline: 1px solid rgb(82, 82, 82);
} */
.my-forms::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}
.my-forms:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}
.my-forms::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}
.my-forms:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color:#fff;
}
ul{
  list-style-type: none
}
a{
  color:#000;
}
a:hover{
  color:#000;
  text-decoration: none;
}
.img-responsive{
  display: block;
  width: 100%;
  height: auto;
}
.mT-4{
  margin-top: 4px;
}
.nav-item a{
  cursor: pointer;
}
.client_review {
  overflow: auto;
}
.mobile_trigger{
  display: none;
}
.cm_box{
  padding: 0px 42px;
}
.mobile_trigger .fa-bars {
  font-size: 20px;
  color: #fff;
}
.logo {
  float: left;
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover ,.navbar-dark .navbar-nav .nav-link {
  color: #fff;
  font-weight: 500;
}
.dropdown-menu {
  list-style: none;
  background-color: #a3a3a3e0;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.15);
  border-radius: 14px;
  box-shadow: inset 0px 0px 24px #0000001f;
}
.searchfield{
  display: none;
}
.menu_open .searchfield {
  display: block;
}
.dropdown-item , .dropdown-item:hover{
  padding: 9px 1.5rem;
  font-weight: 500;
  color: #ffffff;
  text-decoration: none;
    background-color: transparent;
}
.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #061E1E;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%)
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.btn-primary{
  background: linear-gradient(#9ACDDC ,#91DCDD);
  padding: 10px 10px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 4px 5px #b3b1b1;
  border: none;
}
.btn-default{
  background: #B0B0B0;
  padding: 10px 10px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 4px 5px #b3b1b1;
}
.btn-default:hover{
  color:#fff;
}
.btn-coninue , .btn-coninue:hover{
  background:#061E1E;
  color:#fff;
}
.btn-edit , .btn-edit:focus{
  background: #FDFD96;
  padding: 5px 23px;
  font-size: 14px;
  color: #040404;
  box-shadow: 0px 4px 5px #b3b1b1;
  border-radius: 50px;
  margin-left: 20px;
  /*width: 88px;*/
}
.light_text {
  color: #061E1E;
  font-size: 20px;
}
/* .sortingdiv{
  margin-right: 9%;
} */
.sortingdiv p{
  border-radius: 8px;background: #5A7F80;padding: 8px;
}
.sortingdiv p span{
  padding: 12px;
  color: #a0c4c5;
  cursor: pointer;
}
.sortingdiv p span.active {
  color: #fff;
  font-weight: 500;
}
.cm_search{
padding-left: 64px;
margin-top: 0px;
margin-bottom: 10px;
padding-right: 64px;
}
.cm_sort{
  padding-right: 64px;
  margin-top: 12px;
  }
.delete , .delete:focus{
  background: #FEAFBC;
}

/* ---------------------------navbar style----------------------------- */
ul.navbar-nav.mr-auto.cm-nav {
  margin-top: 0px;
  margin-bottom: 0px;
}
.img-logo {
  width: 64%;
  height: auto;
}
/* .cm-navbar {
  padding: .5rem 1rem ;
  background-image: linear-gradient(#9DC7DC, #9ee5ea);
} */
.cm_navbar-brand, .cm_navbar-brand:hover{
  font-size: 34px;
  color: #fff;
  font-family: 'AvantGardeBkBt';
}
.client_review_second {
  color: #95989A;
}
.login-btn{
  background: #061E1E;
  border-radius: 9px;
  padding: 8px 23px !important;
  color: #fff !important;
  margin-left: 10px;
}
/*--------------inner header-------------*/
.open .dropdown-menu {
  display: block;
}
.cm-navbar {
  padding: .5rem 1rem ;
  background-image: linear-gradient(#9DC7DC, #9ee5ea);
  display:flex;
  justify-content: space-between;
  box-shadow: 0px 0px 6px #0000005c;

}
.dropdown-img {
  position: relative;
  top: 0;
  left: 0;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}

/* ----------------------------------left-home------------------------------------ */
.servicePopup{
  padding: 0px
}
.serviceheader{
  position: relative;
  left: 50%;
  top: 2%;
  transform: translatex(-50%);
  font-size: 22px;
}
.cm_bg{
  background: #fff;
  max-width: 1200px;
  margin: 54px auto;
}
.dashboard {
  padding: 40px 0px;
}

.services-offered table {
  border-collapse: collapse;
  width: 100%;
}
.services-offered tr:first-child {
  border-top: 1px solid #ddd;
}
.services-offered th, td {
  padding: 16px;
  text-align: left;
  color:#727272;
  border-bottom: 1px solid #ddd;
}
.services-offered .cm_table{
  margin: 40px 16px;
}
.services-offered svg {
  width: 23px;
}
.services-offered h5 {
  padding-left: 14px;
}
.our_team {
  margin: 40px 0px;
}
.team_member{
  display: flex;
  align-items: center;
}
.services-offered tr td:last-child {
  text-align: right;
  cursor: pointer;
  width: 20%;
}
.services-offered tr td:first-child {
  text-align: left;
  width: 40%;
}
.services-offered tr td:nth-child(2) , .services-offered tr td:nth-child(3) {
  width: 20%;
}
.services-offered tr:hover {
  background: #eee;
  cursor: pointer;
}
.round-img  p{
  text-align: center;
  margin: 9px 0px;
}

.circle-img{
  width: 130px;
  height: 130px;
  padding: 8px;
  object-fit: cover;
}
.circle-img {
  border-radius: 100px;
}
.time-deatils{
  padding: 0px !important;
  margin: 20px 0;
}
.leftspan{
  float: left;
  background: white;
    position: relative;
    bottom: 13px;
    padding: 0px 4px 0 0;
}
.rightspan{
  float: right;
  background: #ffffff;
  position: relative;
  bottom: 13px;
  padding: 0px 0px 0 4px;
}
.border-dot{
    background-image: linear-gradient(to right, #888 10%, #fff 0%);
    background-position: top;
    background-size: 15px 2px;
    background-repeat: repeat-x;
    float: left;
    width: 100%;

}
.nodiv{
  background: rgba(230, 230, 230, 0.51);
  display: flex;
  align-items:center !important;
  justify-content: space-around;
  color:#000;
  height: 54px;
}

.nodiv p {
  margin: 0;
}

.circle-img:hover , .circle-img:active , img.circle-img.active{
  border-radius: 100px;
  border: 2px solid black;
}
.member_discription{
  text-align: center;

}
.member_discription p {
  padding: 20px 0px;
}
.member_work{
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-flow: nowrap;
    overflow-y: auto;
}
.member_work img {
  width: 114px;
    padding: 8px;
    height: 136px;
    object-fit:cover
}
.react-tabs__tab-list {
  border-bottom: none !important;
  margin: 0 0 0px !important;
  padding: 0 !important;
  white-space: nowrap;
  max-width: 100%;
  overflow-y: auto;
  min-height: 223px;
}
.react-tabs__tab--selected {
  background: transparent !important;
  border-color: transparent !important;
  color: black !important;
  border-radius: 0 !important;
}
.react-tabs__tab {
  display: inline-block;
  border: none !important;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 16px 0px !important;
  cursor: pointer;
}
.react-tabs__tab {
  display: inline-block;
  border: none;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 0px 0px;
  cursor: pointer;
}
.react-tabs__tab:focus {
  box-shadow: none !important;
  border-color: transparent !important;
  outline: none;
}
/* -------------------------------right-home---------------------------------------- */
.mapouter{
  position:relative;text-align:right;height:auto;width:100%;padding-top: 46px;
}
.gmap_canvas {
  overflow: hidden;
  background: none!important;
  height: 10vw;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #888;
  min-height: 228px;
}
.imgheight{
  height: 230px;
  border-radius: 8px;
  object-fit: cover;
}

.discription_text{
  color: #95989A;
  padding: 4px 0px;
}

.opening_time{
  padding: 26px 0px;
}
.opening_time h3 {
  padding: 8px 0px;
  font-weight: 400;
}
.opening_time div {
  padding: 0px 26px;
}
.opening_time div p{
  color: #95989A;
}
.comment {
  margin-top: 36px;
}
.rating-header{
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.rating-header > span:nth-child(1) {
  font-size: 50px;
  color: #AB9567;
  font-weight: 500;
  text-shadow:0px 0px 1px #a2a2a2;
}
.rating-header > span:nth-child(2) {
  /* margin-left: 46px;  */
}
span.Star span {
  color: #ab9567;
  font-size: 30px;
  padding: 0px 6px;
  text-shadow: 0px 2px 4px #a2a2a2;
}
.rating-header > span:nth-child(3) {
  font-size: 30px;
  font-weight: 500;
}
.client_review_first{
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
.push {
  margin-left: auto;
}
.push span.fa.fa-star, .push span.fa.fa-star-half-o {
  color: #aa9567;
  padding: 0px 2px;
}
.client_review_first > div:nth-child(1) {
  margin-right: 12px;
}
.client_review_first > div:nth-child(2) >span:nth-child(1) {
  color: black;
  font-weight: 600;
  font-size: 22px;
}
.client_review_first > div:nth-child(3){

}
.client_review_first > div:nth-child(3) > span:nth-child(1) {
  font-size: 16px;
  color: red;
}
/* -----------------------------------lgoin popup------------------------------------ */
.modealPrev i {
  float: left;
  color: #000;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
button.close {
  opacity: 1 !important;
}
.popup-content , .modal-content  {
  border-radius: 20px;
  box-shadow:  0px 0px 8px #5d5d5d;
  border-color: #707070 !important;
  background: rgba(255, 255, 255, 0.81) !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.popupLogo{
  width: 170px;
}
.loginPopup {
  max-width: 400px;
  margin: 0 auto;
}
.login_h3 {
  margin-bottom: 20px;
}
.loginPopup h5 {
  padding: 5px 0px;
}
.myprofile_h2{
  padding: 20px 26px;
}
.loginPopup p {
  color: #061E1E;
  text-align: justify;
  text-align-last: center;
  font-weight: 600;
  font-size: 18px;
  padding: 30px 0px;
}

.btns_padding {
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
}

.facebook-btn{
  background: #3B5998;
  color: #fff;
  padding: 8px 12px;
  font-size: 22px;
}
.facebook-btn:hover {
  color: #fff;
}

.facebook-btn img {
  width: 25px;
}

.gmail-btn {
  background: linear-gradient(180deg, rgba(154,202,220,1) 0%, rgba(154,204,220,1) 0%, rgba(154,204,220,1) 0%, rgba(146,219,221,0.9360119047619048) 100%);
  padding: 8px 10px;
  font-size: 22px;
  color: #fff;
}
.gmail-btn:hover{
  color: #fff;
}

.cm_discript{
  font-size: 14px!important;
  font-weight: 400 !important;
}
.form-control  , .form-control:focus{
  height: calc(2.5em + .75rem + 2px);
  padding: .375rem .75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}
/* -----------------------------------booking page------------------------------------ */
.team-round-img {
  position: absolute;
  left: 50%;
  top: 76%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.cm_team-round-img{
  width: 140px;
  height: 140px;
  border-radius: 100px;
  position: relative;
}
.account_img{
  margin: 30px 0px;
  position: relative;
}
.editmyaccount-content {
    width: 46% !important;
    background: #fff !important;

}
.account-table span{
  text-decoration: underline;
}
.cm_form , .cm_form:focus{
  background:#fafafa;
  border-style: none !important;
  border-bottom: 2px solid #888  !important;
  outline: none !important;
  height: auto;
  border-radius: 0px !important;
  border-radius: 0px;
}
/* .cm_form:focus{
  background: #ffffff;
  border-style: none !important;
  border-bottom: 2px solid #888  !important;
  outline: none !important;
  height: auto;
  border-radius: 0px;
  padding: 0px;
} */
.circleform, .circleform:hover, .circleform:focus {
  border-radius: 100px;
  padding-left: 30px;
}
.save-btn{
  background: #9EE4EA;
}
/* -----------------------------------booking confirm popup------------------------------------ */
.booking_bg{
  background: #fff;
  border-radius: 12px;
}
.booking_dateinfo{
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 4px;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
}
.review_star span {
  color: #aa9567;
  padding: 0px 3px;
  font-size: 30px;
}
.Stylist-detail{
  display:flex;
  align-items: center;
  margin-top: 20px;
}
.profile_img {
  margin: 20px 20px 20px 6px;
}
.Stylist-detail .profile_img img {
  width: 100px;
  height: 100px;
}
.profile-info h5, .profile-info h6, .profile-info p{
  margin-bottom: 0px;
}
.confrim-msz{
  padding: 10px 20px;
}
.img-14round{
  border-radius: 14px;
}
.image-gallery-thumbnail-inner img {
  object-fit: cover;
  width: 90px;
  height: 100px;
}
/* -----------------------------------dashboard------------------------------------ */

.contentBg{
  background: #fff;
  /* display: flex;
  justify-content: space-between; */
  border-radius: 12px;
  padding: 12px;
  margin: 0px 20px;
  padding: 12px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 7px #bbb;
}
ul.sidenavbar {
  list-style-type: none;
  background: #8AA9AE;
  padding: 12px;
  margin: 0px 20px;
  border-radius: 21px;
}
ul.sidenavbar li {
  display: block;
}
ul.sidenavbar li  {
  display: block;
  color: #B8D7DC;
  padding:0px 16px;
  text-decoration: none;
  font-size: 20px;
}

/* Change the link color on hover */
ul.sidenavbar li:hover , ul.sidenavbar li:active , ul.sidenavbar li .active  {
  background-color: transparent;
  color: white;
}
ul.sidenavbar li:after{
  border:none !important;
  background: transparent !important;
}
.booking-footer{
  display: flex;
  flex-flow: row wrap;
  -webkit-flex-flow: row wrap;
  justify-content: space-between;
  margin: 14px 0px;
  align-items: flex-end;
}
.booking-footer div p{
  margin-bottom: 0rem;
}
.cm_bookin_deatils{
    padding: 5px 28px 30px;
}
.booking_tab {
  padding-bottom: 30px;
    padding-top: 0px;
}
.mT80{
  margin-top: 85px;
}
/*-----------------------------------dashboard review ---------------------- */
.push.star-size span {
  font-size: 26px;
}
.review-section{
  padding: 12px;
}
.review-btn{
  float: right;
}
/* ------------------------------dashboard footer------------------------  */
footer{
  background: #8EA3AD;
  display: flex;
  justify-content: flex-end ;
}
.footer-section{
  min-height: calc(100vh - 146px);
}
.social-icon a i {
  font-size: 38px;
  padding: 10px;
  color: #fff;
}
.social-icon {
  margin: 10px 20px 10px 0px;
}
/* ---------------------------------image modal popup------------------------------- */
.popup-content.image_modal-content  {
  background: transparent !important;
  box-shadow: none;
  border: none !important;
  width: 34% !important;
}
.image-gallery-fullscreen-button::before, .image-gallery-play-button::before, .image-gallery-left-nav::before, .image-gallery-right-nav::before {
  color: transparent;
  line-height: 0;
  text-shadow: none;
  transition: none;
}
.image-gallery-fullscreen-button::before, .image-gallery-play-button::before {
  font-size: 0;
  padding: 0;
  text-shadow: none;
}
.image-gallery-thumbnails {
  margin-top: 14px;
}
.image-gallery-slide {
  background: transparent;
}
.image-gallery-slide img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}
.image_modal-overlay button.close {
  color: #fff;
  font-size: 54px;
  font-weight: 100;
  position: absolute;
  top: 22px;
  left: -31px;
}
.image-gallery-thumbnail img {
  border-radius: 6px;
  box-shadow: 0px 0px 8px #000;
}
.image-gallery-thumbnail.active {
  border: none;
}
.image-gallery-thumbnail.active img {
  box-shadow: none !important;
}
.image-gallery-thumbnails{
  overflow-x: auto;
    width: 100%;
}
/* .image-gallery-thumbnails-container{
  overflow-y: auto;
} */
/* ---------------------------------calender and time slot------------------------------- */
.cm_pos_relative {
  position: relative;
}
/* .cm_calendar {
  position: absolute;
  bottom: 4px;
  transform: translateY(-50%);
  z-index: 9999;
  right: 154%;
} */

/* .cm_calendar {
  position: absolute;
  bottom: -219px;
  transform: translateY(-50%);
  z-index: 9999;
  right: 26%;
  min-height: 600px;
  background: #DCDCDC;
} */

.modal-body.calender {
  padding: 4px;
}
.react-calendar__month-view__days {
  display: flex;
  overflow: hidden;
}
.cm_calendar .modal-body {
  padding-top: 0rem !important;
}
.modal-body {
  padding: .8rem;
}
.cm_calendar .month{
    text-align: center!important;
    font-weight: 700;
    padding-bottom: 12px;
    text-transform: capitalize;

}
.add-service{
 padding: 0px 10px;
}
span.addservices {
  width: 20px;
  height: 20px;
  position: absolute;
}

/* .react-calendar button abbr {
  border: 1px solid red;
  background: pink;
  border-radius: 40px;
} */
.react-calendar__month-view__days {
  display: block;
  white-space: nowrap !important;
  overflow-x: auto;
  flex-wrap: nowrap !important;
}
.member_work img {
  border-radius: 14px;
}
button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth , button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend , button.react-calendar__tile.react-calendar__month-view__days__day{
  width: 50px !important;
  max-width: 50px !important;
  overflow: initial !important;
  border: 1px solid #061E1E;
  background: transparent;
  border-radius: 100%;
  margin: 0px 9.5px;
  min-width: 44px;
  min-height: 44px;
  color: #061E1E;
  line-height: 1;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center strong {
  position: absolute;
  top: -68%;
}
.react-calendar__tile--active {
  background: #006edc;
  color: #061E1E;
}
.react-calendar__month-view__days {
  padding: 12px 0px;
}
button.react-calendar__navigation__label {
  font-weight: 600;
  color: #061E1E;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  color: #061E1E;
}
.react-calendar {
  width: 100% !important;
  background: transparent !important;
  border:none !important;
}
.timeslot{
  min-height: 200px;
}
.pad-0{
  padding-left:0;
  padding-right: 0;
}
.timeslot-date{

  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
  margin-bottom: 30px;
  border-bottom: 1px solid #d4d4d4;
}

.timeslot-date h5{
  color: #061E1E;
  text-align: center;
  font-size: 14px;
}
.timeslot ul {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0px;
  display: inline-flex;
  flex-wrap: wrap;
  max-width: 295px;
}
li.cal-active {
  background: #061E1E !important;
  color: #91DEDD !important;
  position: relative;
}
li.selected-time:after {
  content: "";
  position: absolute;
  height: 5px;
  border-top: 2px solid #061e1e;
  width: 27px;
  left: -19%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.timeslot ul li , .timeslot ul li a{
  font-size: 14px;
  background: #91DEDD;
  color: #061E1E;
  width: 52px;
  border-radius: 8px;
  margin: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
  position: relative;
}
.timeslot ul li.active, .timeslot ul li.active a {
  background: #061E1E;
  color: #91DEDD;
}
table.confirm-book {
  width: 100%;
}
table.confirm-book tr td:first-child{
  width: 40%;
  text-align: center;
}
table.confirm-book tr {
  font-weight: 500;
}
table.confirm-book tr td {
  font-size: 16px;
  color: #061e1e;
  width: 24%;
  text-align: center;
}
.pad-14{
  padding: 14px 0px;
}
.height80vh{
  max-height: 80vh;
}
.booknow-btn{
  background: #061E1E;
  color:#fff;
  margin: 20px;
  width: 80%;
}
.booknow-btn{
  color:#fff !important;

}
.cm_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.8);
  z-index: 9999;
}

.review-circle-img{
  border-radius: 100px;
  width: 60px;
  height: 60px;
}
.cm_bookin_deatils span {
  padding: 0px 6px;
}
.cm_bookin_deatils .active {
  border-bottom: 3px solid;
  padding-bottom: 8px;
}
.notfound {
  /* width: 100%;
  line-height: 1.4;
  text-align: center;
  margin-top: 100px; */
  width: 100%;
    line-height: 1.4;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 252px;
  font-weight: 900;
  margin: 0px;
  /* color: #262626; */
  color: #789eaf;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1>span {
  text-shadow: -8px 0px 0px #fff;
}

.notfound .notfound-404 h3 {
  font-family: 'Cabin', sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0px;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound h2 {
  font-family: 'Cabin', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 50px;
  margin-bottom: 25px;
}
.accountleft p {
  padding: 12px;
}
.border-right {
  border-right: 1px solid #7a7a7a!important;
}
.cm_error_msg, .account-table span .cm_error_msg{
  color: red;
    font-size: 14px;
    margin-left: 4px;
    /* padding-bottom: 10px; */
    text-decoration: none !important;
}
.form-control:focus {
  border-color: transparent ;
  outline: none;
  box-shadow: none;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center{
  width: 48px !important;
  height: 44px !important;
  max-width: 50px !important;
  overflow: initial !important;
  border: 1px solid #91dedd;
  background: #91dedd;
  border-radius: 100%;
  margin: 0px 9.5px;
  min-width: 44px;
  min-height: 44px;
  color: #061e1e;
  line-height: 1;
  margin: 0px 0px 0px 0px;
}

.rwdp-DayCircle.rwdp-DayCircle-selected.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center{
  background: #061E1E;
  color: #91dedd;
}
/* ----       for diabled-------- */
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.gray {
  background: #95989A;
  color: #0b0b0b;
  border-color: #95989A;
}
.gray-holiday {
  background: #95989A;
  color: #0b0b0b;
  border-color: #95989A;
  width: 50px !important;
  height: 50px !important;
  max-width: 50px !important;
  overflow: initial !important;
  border: 1px solid #061E1E;
  background: transparent;
  border-radius: 100%;
  margin: 0px 9.5px;
  min-width: 44px;
  min-height: 44px;
  color: #061E1E;
  line-height: 1;
  margin: 0px 0px 0px 0px
}
.skyblue-holiday {
  background: #95989A;
  color: #0b0b0b;
  border-color: #95989A;
}

.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.skyblue {
  background: #91dedd;
  border-color: #91dedd;
  color: #061e1e;
}

.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.White{
  background: transparent;
  border-color: #061E1E ;
  color: #000;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink {
  background: #a9a8a8;
  border-color: #a9a8a8;
  color: #000;
  margin-top: -16px;
  width: 54px !important;
  height: 54px !important;
  border: 2px solid #a9a8a8;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink:active{
  background: #061e1e;
  border-color: #061e1e;
  color: #91dedd;
}
.pink {
  background: pink;
  border-color: pink;
  color: #000;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.darkblue {
  background: #061e1e;
  border-color: #061e1e;
  color: #91dedd;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.red{
  background: red;
  border-color: red;
  color: #91dedd;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.darkblue strong {
  color: #061e1e;
}
.rwdp-DayCircle-container.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.rwdp-DayCircle-unavailable-text{
  display: none;
}

.rwdp-DayCircle-today{
  display: none;
}
.rwdpNextWeekArrow {
  border-left: 20px solid #061E1E !important;
  right: 0;
}
.rwdpPrevWeekArrow {
  border-right: 20px solid #061E1E !important;
  left: 0;
}
.cm_table .fa-check-circle {
  font-size: 23px;
  color: #061E1E;
}
.rwdpDayBoxDesktop .rwdp-DayCircle-container:focus{
  outline: none !important;
}
.rwdp-DayCircle.rwdp-DayCircle-selected.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center strong {
  color: #061e1e;
}
.phn-name{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal-body .table-responsive.cm_table {
  margin: 2px 0px 12px;
}
/* .modal-body .services-offered tr td:first-child {
  text-align: left;
  width: 70%;
} */
/* ------------------------------------------profile----------------------- */
.suggestion-item,
.suggestion-item--active {
  display: block !important;
  width: 100% !important;
  position: static !important;
  z-index: 99 !important;
  color: #fff !important;
  overflow-wrap: break-word !important;
  font-size: 14px !important;
  padding: 10px 0 !important;
  background: #7c999a !important;
  border-bottom: 1px solid #b1b1b1 !important;
}

.suggestion-item:last-child,
.suggestion-item--active:last-child {
  border-bottom: none !important;
  border-radius: 0px 0px 6px 6px !important;
}

.suggestion-item:first-child,
.suggestion-item--active:first-child {
  border-top: 1px solid #fff !important;
  border-radius: 6px 6px 0px 0px !important;
}

.pad0{
  padding: 0px;
}
.account-table table{
  width: 100%;
}
.account-table table tr td:first-child {
  border-right: 1px solid #7b7b7b;
}

.account-table table tr td {
    width: 50%;
    text-align: left;
    border-bottom: none;
    padding: 19px 0;
    padding-left: 40px;
}
.width-auto{
  width: auto !Important;
}
/* ------------------------------------------Home page----------------------- */
.cm_bg_home {
  background: #00000000 !important;
  max-width: 1600px;
  margin:20px auto;
}
.home , .home-search {
  padding-left: 12px;
  margin-left: 50px;
  padding-right: 12px;
  margin-right: 50px;
}
.homeimge-height{
  cursor: pointer;
    height: 315px;
    object-fit: cover;
}

.autocomplete-dropdown-container {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 99;
  background: #597f80 !important;
  color: #fff;
  overflow-wrap: break-word;
}
button:focus {
  outline: none;
}
.hairdreser_img
{
  width: 100%;
  min-height: 300px;
  border-radius: 14px;
  box-shadow:0px 0px 5px #7f7f7f;
}
.home-box{
  background: #fff;
    border-radius: 14px;
    box-shadow: 0px 0px 6px #888;
    padding: 12px;
    margin-top: 20px;
    margin-bottom: 42px;
    min-height: 564px;
    cursor: pointer;
}
.home.home-box:hover {
  box-shadow: 0px 0px 30px #6b6b6b;
}
.homerating {
  padding: 0px 8px;
  margin-bottom: 12px;
}
.home-box img.img-fluid , .home-box img.img-thumbnail {
  max-width: 100% ;
  height: auto ;
  min-width: 100% ;
  border-radius: 14px;
}
.p12 {
  padding: 12px 12px;
}
.homeimge-height{
  cursor: pointer;
    height: 315px !important;
    object-fit: cover;
}
.homerating > span:nth-child(1) {
  font-size: 24px;
}
.homerating  > span:nth-child(2) {
  margin-left: 8px;
}
.homerating span.Star span {
  font-size: 20px;
  padding: 2px 4px;
}
.homerating > span:nth-child(3) {
  font-size: 20px;
}
.photobox {
  display: flex;
  flex-flow: row;
  overflow: auto;
  max-width: 324px;
  position: relative;
  bottom: -8px;
}
.photobox img {
  width: 80px;
  height: 100px;
  padding: 8px 4px;
  border-radius: 14px;
  object-fit: cover;
}
.flex-container {
  display: flex;
  align-items: flex-end;
}
.view_service_btn {
  background: linear-gradient(#9ACDDC ,#91DCDD);
  border-radius: 60px;
  font-size: 16px;
  padding: 4px 26px 0 11px;
  border: none;
  position: relative;
  font-weight: 400;
  min-width: 160px;
  text-align: right;
  padding-right: 40px;
  font-weight: 500;
}
.view_service_btn .fa-angle-right {
  font-size: 32px;
    position: absolute;
    right: 4%;
    top: 50%;
    transform: translate(-50%, -50%);
}
.top_home{
  display: flex;
  /* margin-top:42px; */
  justify-content: space-between;
}
.p-rel{
  position: relative;
}
.my-forms {
  font-size: 14px;
  padding: 10px 6px;
  border-radius: 6px;
  border: none;
  margin-right: 12px;
  background: #5A7F80;
  color: #fff;
  min-width: 160px;
  max-width: 160px;
  margin-top: 12px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-weight: 600;
}
.btn.btn-xs.searchbtn {
  background: #5A7F80;
  border-radius: 6px;
  color: #fff;
}
ul.react-tabs__tab-list.booking {
  min-height: auto !important;
  overflow: hidden;
}
ul.booking .react-tabs__tab {
  margin-right: 20px;
    color: #888;
    font-weight: 400;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
li.react-tabs__tab.react-tabs__tab--selected {
  color: #3c3c3c !important;
    font-weight: 500;
}
.react-tabs__tab--selected:after {
  content: "";
    position: absolute;
    height: 3px;
    left: -4px;
    right: -4px;
    top: 50px;
    background: #3c3c3c;
}
.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background:transparent !important;
}
.react-tabs__tab:focus{
  background: transparent !important;
}
.uplod_fafa{
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 20px;
  background: #040404;
  padding: 10px;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
}
.cm_calendar button.close {
  font-size: 31px;
  padding: 0px 14px;
}
.cursorP{
  cursor: pointer;
}
.services-offered tr td:first-child {
  font-weight: 500;
}
.blure_bg{
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);

}
.algnC{
  align-items: center;
}
.algnCc{
  margin-top: -9px;
}
.booking_confirm h4{
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
}
.booking_confirm h5{
  font-size: 15px;
  text-align: right;
}
.colgray{
  color: #888;
}
.cm_txtmsz{
  font-size: 13px !Important;
    padding: 5px !Important;
    margin-bottom: 0px;
}
.view_service_btn {
  padding: 0px 40px 3px 18px;
}
.view_service_btn p{
  margin-bottom: 0;
}
.hideBigscreen{
  display: none;
}

.cm_min_card_content_height {
  min-height: 88px;
}

.cm_min_slide {
  position: relative;
  min-height: 118px;
}

.cm_view_service_btn {
  position: absolute;
  right: 12px;
  bottom: 0;
}

span.cm_sort_i,
span.cm_sort_i span {
  padding: 0;
}

span.cm_sort_i img {
  width: 22px;
}
/* ---------------------------------------------------------------new booking start----------------------------- */
.body_container {
  /* min-height: 100vh; */
  position: relative;
  padding: 22px 1.5rem 22px calc(250px + 1.5rem);
}
.sidenav {
  box-shadow: 0 0 15px 0 rgba(0,0,0,.05);
  width: 250px;
  position: fixed;
  top: 0;
  height: 100%;
  left: 0;
  background: linear-gradient(#c8edee, #daceec);
  padding: 10px;
  z-index: 999;
  padding-top: 80px;
}

.sidenav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidenav ul li {
  margin-bottom: 30px;
}

.sidenav ul li a {
  display: block;
  padding: 10px;
  border-radius: 10px;
}

.sidenav ul li a.active,
.sidenav ul li a:hover {
  background: #e6ffff;
}

.menu_icon img {
  width: 24px;
}

.sidenav_text {
  margin-left: 20px;
}

.menu_toogle_icon {
  position: absolute;
  top: 19px;
  left: 19px;
  cursor: pointer;
}

.menu_toogle_icon img {
  width: 24px;
}

.nav_menu_open .sidenav {
  width: 60px;
}

.nav_menu_open .sidenav_text {
  display: none !important;
}

.nav_menu_open .sidenav ul li a {
  width: 46px;
  border-radius: 100%;
}

.nav_menu_open .body_container {
  padding: 22px 1.5rem 22px calc(60px + 1.5rem);
}

.top_header {
  padding: .8rem 2.2rem;
  width: calc(100% - 250px);
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  background: #F7F7F7;
  text-align: center;
  min-height: 70px;
}

.nav_menu_open .top_header {
  width: calc(100% - 60px);
}

.top_header h1 {
  font-size: 30px;
  font-weight: 400;
}
.svg_sidenav svg {
  width: 20px;
  height: 20px;
}

/* --sticky button-------- */
.add-newbooking {
  background-color: #061E1E;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  position: relative;
  box-shadow: 0px 0px 11px #666666;
}
#addbutton {
  position: fixed;
  bottom: 22px;
  right: 22px;
  z-index: 9999;
}
.add-newbooking svg {
  width: 20px;
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    
}
/* ---------------booking-------------- */
.booking-parent {
  padding: 3rem 0rem 1rem 3rem;
}
.new-booking-tab .nav-item.nav-link.active {
  background-color: transparent;
  border: none;
}


.new-booking-tab .nav-item {
  font-size: 24px;
  color: #000;
  margin-bottom: 30px;
  position: relative;
  font-weight: 300;
}

.new-booking-tab .nav-item.active {
  color: #000;
  font-weight: 600;
}

.new-booking-tab .nav-item.active:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: 9px;
  height: 4px;
  background: #BFE4EA;
  left: 0;
}

.bg_white {
  background: white;
  min-height: calc(100vh - 68px);
}

.new-booking-tab {
  border-bottom: 0;
}

.cm_picker_wrap {
  position: relative;
}

.cm_picker_wrap .react-datepicker__input-container {
  position: absolute !important;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
}

.cm_picker_wrap .react-datepicker__input-container input {
  height: 100%;
  cursor: pointer;
}

.cm_picker_wrap > p {
  margin: 0;
  font-size: 20px;
}

.cm_picker_wrap .react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0;
}

.booking_form_wrapper {
  box-shadow: 0px 3px 6px rgba(0,0,0,0.31);
  border-radius: 9px;
  padding: 30px;
}


.cm_row > div {
  display: inline-block;
  vertical-align: top;
}

.cm_25 {
  width: 150px;
  margin-right: 10px;
}

.cm_75 {
  width: calc(100% - 161px);
}


.cm_select {
  box-shadow: none;
  width: 100%;
  text-align: left;
  background: transparent;
  color: #000;
  border: 1px solid #b0b3b5 !important;
  font-size: 23px;
  position: relative;
}

.cm_select:after {
  position: absolute;
  right: 15px;
  top: 24px;
}


.btn-primary:focus,
.cm_select:hover {
  color: #000;
  background-color: transparent;
}

.cm_select:not(:disabled):not(.disabled).active, .cm_select:not(:disabled):not(.disabled):active, .show>.cm_select.dropdown-toggle {
  color: #000;
  background-color: transparent;
}

.cm_select_wrapper .dropdown-menu {
  background: #fff;
  border: 1px solid #b0b3b5 !important;
  width: 100%;
  box-shadow: none;
  border-radius: 6px;
  position: relative !important;
  transform: none !important;
  right: 0 !important;
  top: 5px !important;
  padding: 0;
}

ul.cm_select_ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.cm_select_ul li {
  border-bottom: 1px solid rgba(112, 112, 112, 0.5);
}

ul.cm_select_ul li:first-child {
  border-top: 1px solid rgba(112, 112, 112, 0.5);
}

ul.cm_select_ul li a {
  color: #727272;
  font-weight: 400;
}

ul.cm_select_ul li a:hover {
  color: #000;
}

.cm_select_wrapper .dropdown-menu h4 {
  font-size: 21px;
  padding: 16px;
}



.tab_content_wrapper {
  display: inline-block;
  max-width: 60%;
  width: 100%;
}

.cm_right_panal {
  display: inline-block;
  width: calc(40% - 30px);
  margin-left: 30px;
  vertical-align: top;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.31);
  border-radius: 9px;
  padding: 30px;
  margin-bottom: 274px;
  position: relative;
}

ul.buttons_border_wrap {
  position: absolute;
  margin: 0 -30px;
  top: calc(100% + 145px);
  padding: 0;
  list-style: none;
  border: 1px solid #061E1E;
  border-radius: 9px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  background: #fff;
  width: 100%;
}

ul.buttons_border_wrap li {
  cursor: pointer;
  padding: 10px 15px;
  font-size: 20px;
  border-top: 1px solid rgba(0,0,0,0.5);
  text-align: center;
}

ul.buttons_border_wrap li:first-child {
  border: 0 !important;
}

.cm_red_c {
  color: #FF002A;
}

.not_working {
  position: absolute;
  width: calc(100% + 26px);
  height: 30px;
  left: -26px;
  top: -15px;
  background: rgba(0, 0, 0, 0.1);
}

.salonName {
  font-weight: 500;
  font-size: 20px;
}



.cm_search_wrapper {
    margin-top: 20px;
    position: relative;
}

.cm_search_wrapper i {
    position: absolute;
    right: 20px;
    top: 18px;
}

.cm_bao {
  background: #BFE4EA;
  margin: -30px;
  margin-bottom: 15px;
  border-radius: 10px 10px 0 0;
  position: relative;
  padding: 15px;
  padding-right: 95px;
}

.cm_bao img {
  position: absolute;
  right: 35px;
  top: 15px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.cm_bao .dropdown {
  position: absolute;
  right: 15px;
  top: 15px;
}

.cm_bao .dropdown-toggle {
  background: transparent;
  box-shadow: none;
  color: #000;
  padding: 0;
}

.cm_bao .dropdown-toggle::after {
  display: none;
}

.cm_bao .dropdown-menu {
  left: auto !important;
  right: 0 !important;
  transform: none !important;
  top: 40px !important;
}
.cm_bao .btn-primary:not(:disabled):not(.disabled).active:focus, .cm_bao .btn-primary:not(:disabled):not(.disabled):active:focus, .cm_bao .show>.btn-primary.dropdown-toggle:focus
.cm_bao .btn-primary:not(:disabled):not(.disabled).active, .cm_bao .btn-primary:not(:disabled):not(.disabled):active, .cm_bao .show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.cm_bao h3 {
  font-size: 22px;
}

.cm_bao p {
  margin: 0;
}


.cm_right_panal .tab-content {
  margin-left: -30px;
  margin-right: -30px;
}

.cm_right_panal .card-header {
  padding: 0 ;
  background-color: transparent;
}

.cm_right_panal .btn-link {
  font-weight: 400;
  color: #000000;
  text-decoration: none;
}

.cm_right_panal .card-body ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.card-body ul li {
  border-bottom: 1px solid #e8e8e8;
  padding: 5px 15px;
}

.accordion {
  margin-bottom: 50px;
}

.accordion .card-body {
  padding: 0;
}

.card-body ul li h4,
.card-body ul li p {
  margin: 0;
}

.cm_bg_uniq {
  background: #D6D5EC;
  padding: 5px 15px;
  margin: -5px;
  margin-left: -15px;
}

.cm_bg_uniq_dark {
  background: #E3BEEB;
}

.m_revers {
  position: relative;
  top: 150px;
  margin: -30px;
}

.cm_btns .btn-dark {
  padding: 10px 10px;
  font-size: 24px;
  color: #fff;
  box-shadow: 0px 4px 5px #b3b1b1;
  border: none;
}

.payment_detail {
  text-align: right;
}

.cm_ammount {
  margin-bottom: 30px;
  margin-top: 30px;
}

.cm_ammount p {
  margin-bottom: 5px;
}

.cm_ammount h4 {
  border: 1px solid #061E1E;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
}

.cm_popup .modal-content {
  background: #fff !important;
}

.cm_popup .modal-header {
  background: #BFE4EA;
  margin-top: -8px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 20px;
}


.cm_staff_catd {
  background: #F7F7F7;
  border-radius: 9px 9px 0 0;
  position: relative;
  padding: 19px 15px;
  padding-right: 50px;
  cursor: pointer;
}

.cm_staff_catd img {
  position: absolute;
  right: 14px;
  top: 11px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.cm_staff_catd h3 {
  font-size: 20px;
  margin: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 300;
}

.cm_staff_catd.active {
  background: linear-gradient(#BCEBEA ,#C4DCEA);
}
.cm_staff_catd.active h3 {
  font-weight: 500;
}

.cm_over_auto {
  overflow: auto;
  position: absolute;
  width: 100%;
  top: -69px;
}


.cm_box_view {
  padding: 15px;
  box-shadow: 1px 3px 6px rgba(0,0,0,0.31);
  position: relative;
  margin-top: 100px;
  padding-top: 40px;
  border-radius: 9px;
}

.srv_search {
  max-width: 320px;
  margin-bottom: 36px;
}

.srv_search .form-control {
  min-width: 320px !important;
}

.srvc_table {
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.cm_tab_row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #DADADA;
  margin: 0 -15px;
  cursor: pointer;
}

span.cm_b_span {
  display: block;
  height: 100%;
}

.cm_tab_row.selected {
  background: #fff;
  cursor: default;
}
.cm_main_col {
  width: 50%;
  position: relative;
  padding: 20px;
  padding-left: 70px;
  
}

.cm_main_col_2,
.cm_main_col_3 {
  width: 25%;
  text-align: center;
  padding: 5px;
}
.cm_main_col img {
  position: absolute;
  left: 14px;
  top: 11px;
  border-radius: 100%;
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.cm_main_col p {
  margin: 0;
  cursor: pointer;
}

.cm_table_input {
  width: 110px;
  margin: 0 auto;
}

.cm_main_col_3 {
  position: relative;
  padding-right: 30px;
}

.cm_main_col_3 i {
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  cursor: pointer;
}

.cross_wrap {
  padding-right: 30px;
}

.cross_wrap i {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.seetings_wrap_box {
  padding: 0 15px;
  box-shadow: 1px 3px 6px rgba(0,0,0,0.31);
  position: relative;
}

.border_right {
  border-right: 1px solid rgba(114, 114, 114, 0.5);
}

.file_upload_wrap {
  position: relative;
  text-align: center;
  background: #f7f7f7;
  border-radius: 9px;
}

.file_upload_wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  z-index: 99;
  cursor: pointer;
}

.file_upload_wrap img {
  width: 100%;
  max-height: 320px;
  object-fit: cover;
  border-radius: 9px;
}


.seetings_wrap_box .col-md-6 {
  padding: 15px 4rem;
}

.link_img img {
  max-height: 270px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.link_img h3 {
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 6px;
}

.text-center.link_img h3 {
  font-weight: 400;
  margin-bottom: 25px;
  margin-top: 6px;
}

.check_wraper {
  position: relative;
  padding-left: 30px;
}

.check_wraper > span {position: absolute;width: 21px;height: 21px;border: 2px solid #061E1E;border-radius: 3px;left: 0;top: 0;}

.check_wraper > input {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.check_wraper > span:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 12px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  left: 5px;
  top: 1px;
  transform: rotate(45deg);
  opacity: 0;
}

.check_wraper > input:checked + span:after {
  opacity: 1;
}
.check_wraper > input:checked + span {
  background: #061E1E;
}

.same_box {
  margin-bottom: 45px;
}

.same_box img {
  max-width: 100%;
}

.form-group.position-relative .form-control {
  padding-left: 50px;
}

img.input__icon {
  position: absolute;
  width: 30px;
  top: 44px;
  left: 10px;
}

.same_box h3 {
  font-weight: 400;
  margin: 30px 0;
}


.hairdress_modal {
  max-width: 100%;
  margin: 0;
}

.hairdress_modal .modal-content {
  background: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
}

.hairdress_modal .modal-header {
  border: 0;
}

.modal_header {
  background: #F7F7F7;
  padding: 15px;
  position: relative;
}

.hairdress_modal_body {
  max-width: 900px;
  margin: 15px auto;
  width: 100%;
  padding: 40px 15px;
  background: #fff;
  border-radius: 9px;
}


.check_wrap {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.check_wrap span {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 3px;
  border: 2px solid #061E1E;
  border-radius: 3px;
}

.check_wrap input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}

.check_wrap span:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 12px;
  border-bottom: 2px solid #061E1E;
  border-right: 2px solid #061E1E;
  transform: rotate(45deg);
  left: 4px;
  top: 0px;
  opacity: 0;
}

.check_wrap input:checked + span:after {
  opacity: 1;
}

.custom__control {
  height: calc(2.5em + .75rem + 2px);
  padding: .375rem .75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}

span.custom__indicator-separator {
  display: none !important;
}

.hairdress_modal_body .form-group {
  margin-bottom: 28px;
}

.cm_close {
  position: absolute;
  right: 20px;
  top: 12px;
  font-size: 30px;
  line-height: 1;
  cursor: pointer;
}

.stap_wrapper {
  text-align: center;
  padding-top: 300px;
  position: relative;
  min-height: 500px;
}

.stap_wrapper img {
  width: auto;
  position: absolute;
  top: 0;
  left: 50%;
  height: 280px;
  transform: translateX(-50%);
  max-width: 100%;
  object-fit: cover;
}


.stap-action {
  text-align: center;
}

.stap-action .btn {
  padding-left: 35px;
  padding-right: 35px;
  font-weight: 600;
}

.stap-indicate {
  margin: 0;
  padding: 0;
  margin-bottom: 25px;
  list-style: none;
  text-align: center;
}

.stap-indicate li {
  width: 18px;
  height: 18px;
  background: rgba(114, 114, 114, 0.25);
  display: inline-block;
  margin: 10px;
  border-radius: 100%;
  cursor: pointer;
}

.stap-indicate li.active {
  background: #C4DDEA;
}

.go-through-modal .modal-content {
  background: #fff !important;
}

.go-through-modal .modal-header {
  border-bottom: 0;
}

.hairdress_login, .modal-content {
  border-radius: 9px;
}

.cm_check_list {
  margin: 0;
  padding: 0;
  display: inline-block;
  text-align: left;
  list-style: none;
}

.cm_check_list li {
  font-size: 13px;
  position: relative;
  padding-left: 30px;
}

.cm_check_list li:before {
  content: '';
  position: absolute;
  width: 6px;
  height: 10px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  left: 10px;
  top: 4px;
  transform: rotate(35deg);
}

.auth_wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  overflow: auto;
  max-height: 100vh;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100%;
}

.btn-dark {
  background-color: #061E1E;
  border-color: #061E1E;
}

.go-through-modal.auth_wrapper .modal-body {
  max-width: 600px;
  margin: 50px auto;
  background: #fff;
  border-radius: 9px;
  padding: 30px;
  width: 100%;
}

.sm_date_picker .react-datepicker-wrapper {
  width: 140px;
  display: block;
}

.full_date_picker .react-datepicker-wrapper {
  display: block;
}

.full_date_picker input,
.sm_date_picker input {
  width: 100%;
  height: calc(2.5em + .75rem + 2px);
  padding: .375rem .75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}

.full_date_picker input:focus,
.sm_date_picker input:focus {
  border: 1px solid #b0b3b5 !important;
  box-shadow: none !important;
}

.add_break_btn {
  text-align: center;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  margin: 15px -15px;
  padding: 15px;
  cursor: pointer;
}

.add_break_btn svg {
  width: 15px;
  margin-right: 20px;
}

.add_break_btn svg rect {fill: #000;}

.break_list > p {
  position: relative;
  margin-top: 15px;
  margin: 0;
}

.break_list > p i {
  position: absolute;
  right: 15px;
  top: 2px;
}

.break_list {border-top: 1px solid #707070;margin: 15px -15px;padding: 15px;}



.join_for_free .facebook-btn {
  background: #061E1E;
  padding: 0px 30px;
  display: inline-block;
  width: auto;
}

.join_for_free .gmail-btn {
  background: #DFF1F4;
  padding: 12px 30px;
  color: #061E1E;
  box-shadow: none;
  width: auto;
  display: inline-block;
}

.join_for_free .gmail-btn,
.join_for_free .facebook-btn {
  min-width: 380px;
}


input.react-autosuggest__input {
  height: calc(2.5em + .75rem + 2px);
  padding: .375rem .75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
  width: 100%;
}





.join_for_free h2 {
  font-size: 44px;
  margin-top: 20px;
}

.join_for_free h3 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 40px;
}

.modal_header h3 {
  font-size: 40px;
}

.stap_wrapper h2 {
  font-size: 38px;
  margin: 20px 0;
}

.stap_wrapper h3 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 23px;
  font-weight: 300;
}

.cm_right_check {
  margin: 35px 0;
}

.cm_right_check .check_wrap {
  top: 6px;
  margin-right: 0;
  margin-left: 10px;
}

.cm_right_check .btn {
  min-width: 220px;
  margin-top: 20px;
}

.cm_fw_l {
  font-weight: 300 !important;
}

.cm_fw_m {
  font-weight: 500 !important;
}

.cm_fw_b {
  font-weight: 600 !important;
}

.h_n label {
  font-weight: 600 !important;
}

.h_n .btn-dark {
  font-weight: 600 ;
}

.cm_bottom_line {
  position: relative;
}

.cm_bottom_line:after {
  content: '';
  position: absolute;
  width: 100%;
  bottom: -2px;
  height: 4px;
  background: #BFE4EA;
  left: 0;
}

td.alfa_text {
  color: #000;
}

.cm_full_date .react-datepicker-wrapper {
  display: block;
}

.setting_page .nav-tabs .nav-link,
.staff_page .nav-tabs .nav-link {
  font-weight: 300;
}
.setting_page .nav-tabs .nav-link.active,
.staff_page .nav-item.nav-link.active {
  font-weight: 600;
}


.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__suggestions-container {
  position: absolute;
  width: 100%;
  background: #fff;
  z-index: 99;
  top: 60px;
  max-height: 267px;
  overflow: auto;
}

.react-autosuggest__suggestions-container ul {
  margin: 0;
  padding: 10px 0;
  list-style: none;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
}

.react-autosuggest__suggestions-container ul li {
  display: block;
  padding: 10px 15px;
  border-top: 1px solid #b0b3b5;
  cursor: pointer;
}

.salonAddress {
  font-size: 14px;
  font-weight: 300;
}

.react-autosuggest__suggestions-container ul li:first-child {
  border: 0;
}

.react-autosuggest__suggestions-container ul li:hover {
  background: #f9f9f9;
}

body body {
  background: #fff !important;
}

.address_input_wrap {
  position: relative;
}

.address_input_wrap .location-search-input {
  height: calc(2.5em + .75rem + 2px);
  padding: .375rem .75rem;
  border: 1px solid #b0b3b5 !important;
  border-radius: 6px;
  width: calc(100% - 0px);
}


.address_input_wrap.w-100 .location-search-input {
  width: 100%;
}

.cm_check_btn {
  position: absolute;
  width: 54px;
  height: 54px;
  border: 1px solid #b0b3b5;
  right: 0;
  top: 0;
  border-radius: 6px;
  text-align: center;
  line-height: 54px;
  font-size: 24px;
  color: #cacaca;
  cursor: pointer;
}



.cm_check_btn:hover {
  color: #000;
}

.cm_check_btn.active {
  border: 1px solid #000;
  color: #000;
}

.address_input_wrap .suggestion-item,
.address_input_wrap .autocomplete_location {
  background: #fff !important;
  color: #000 !important;
}

.address_input_wrap .suggestion-item {
  padding: 10px !important;
}

.address_input_wrap .autocomplete_location {
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  border: 1px solid #b0b3b5;
  border-radius: 6px;
  z-index: 99;
}

.only_shadow{
  padding: 0 15px;
  box-shadow: 1px 3px 6px rgba(0,0,0,0.31);
  position: relative;
}

.cm_PhoneInput .PhoneInputInput, .cm_PhoneInput .PhoneInputInput:focus {
  border: 0;
  outline: none;
}

.text_red {
  color: #FF002A;
}

.pointer_c span, .pointer_c p {
  cursor: pointer;
}

img.PhoneInputCountryIconImg {
  object-fit: cover !important;
  width: 24px !important;
  height: 16px !important;
}

.pointer-event {
  cursor: pointer;
}

.daily-schedule {
  width: 100%;
}

.daily-schedule tr td:first-child, .daily-schedule th td:first-child,
.daily-schedule tr td:last-child, .daily-schedule th td:last-child {
  border: 0 !important;
}

table.daily-schedule tr > td:first-child {
  color: transparent;
  width: 1px !important;
  font-size: 10px;
}

.daily-schedule td.daily-schedule__event {
  width: calc(100% - 50px);
  border-radius: 9px;
  vertical-align: text-top;
  position: relative;
  background: transparent !important;
}

.daily-schedule td.daily-schedule__event > * {
  padding: 0 !important;
}

.booking_card {
  position: absolute !important;
  top: 0;
  width: calc(100% + -10px);
  right: 7px;
  height: calc(100% - 5px);
  left: -10px;
}

.daily-schedule tr td:first-child {
  width: 1px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.new-booking-tab {
  justify-content: center;
}

/* .cm_over_cal {
  position: relative;
}

.times_no {
  position: absolute;
  background: #fff;
  z-index: 9;
  top: 190px;
  left: 0;
}

.diary-parent-wrap {
  overflow: auto;
  overflow-y: hidden;
  position: relative;
  height: 2729px;
} */


.times_no {
  position: fixed;
  background: #fff;
  z-index: 99;
  left: 250px;
  top: 279px;
}

.nav_menu_open .times_no {
  left: 72px;
}

tr.left_bg {
  border-left: 12px solid #D6D5EC;
}


.client_table .table tbody td {
  vertical-align: middle;
}


.CurrencySelect .flag-select__options {
  width: 100%;
  left: 0;
}

.CurrencySelect .flag-select__option__icon {
  top: 0;
}

::-webkit-scrollbar-thumb {
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0);
}
/* ---------------------------------------------------------------new booking end----------------------------- */
/* ------------------------------------------media query start----------------------- */


@media(max-width: 1400px){
  .cm_right_panal {
    width: 400px;
  }

  .tab_content_wrapper {
    max-width: calc(100% - 431px);
  }
}

@media(max-width: 1023px){
  .booking-parent {
    padding: 0;
}
  .cm_right_panal {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .tab_content_wrapper {
    max-width: 100%;
  }

  .invoice_card_wrapper {
    margin: 15px auto;
  }
}

@media (min-width:991px) and (max-width:1260px){
  .rating-header > span:nth-child(1) {
    font-size: 40px;
  }
  .rating-header > span:nth-child(2) {
    margin-left: 0;
  }
  span.Star span {
    font-size: 24px;
  }
  .rating-header > span:nth-child(3) {
    font-size: 22px;
  }
}

@media (min-width:991px) and (max-width: 1199px){

  .gmap_canvas {
    height: 16vw;
  }
  .p12 .text-left , .p12 .text-right
  {
  text-align: left !important;
  }
 
  .flex-container {
    justify-content: space-around;
    padding: 12px 0px;
  }

 
}



@media(min-width: 1200px){
  .top_header .menu_toogle_icon {
    display: none;
}
}
@media(max-width: 1199px){

 

  .home, .home-search {
    margin-left: 0;
    margin-right: 0;
}

.cm_search {
  padding-left: 12px;
}

.cm_sort {
  padding-right: 12px;
}

  .cm_min_slide {
    position: relative;
    min-height: 178px;
    padding-bottom: 65px;
}

.cm_view_service_btn {
  right: auto;
  bottom: 0;
  transform: translateX(-50%);
  left: 50%;
}


.photobox {
  margin: 0 auto;
}


}



@media(max-width: 1100px){
  .sidenav {
    left: -251px;
    z-index: 99999;
    width: 250px !important;
  }
  
  .nav_menu_open .sidenav {
    left: 0;
  }



  .nav_menu_open .body_container,
  .body_container {
    padding: 16px;
  }

  .nav_menu_open .top_header,
  .top_header {
    width: 100%;
  }

  .sidenav ul li a {
    width: 46px;
    border-radius: 100%;
  }
  .nav_menu_open .sidenav ul li a {
    width: 100%;
    border-radius: 10px;
  }

  .sidenav_text {
    display: none !important;
}
  .nav_menu_open .sidenav_text {
    display: inline-block !important;
}

.nav_menu_open .menu__overlay {
  position: fixed;
  width: calc(100% - 250px);
  height: 100%;
  right: 0;
  top: 0;
  background: black;
  z-index: 9999999999;
  opacity: 0.5;
  cursor: e-resize;
}
}


@media(max-width: 991px){
  .cm_view_service_btn {
    padding: 0px 40px 0px 18px !important;
  }

  .my-forms {
    min-width: 110px;
    max-width: 110px;
}
.cm_min_card_content_height,
.cm_min_slide {
  min-height: 1px;
}


.photobox > div {
  display: inline-block;
  
}

.photobox {
  display: block;
  white-space: nowrap;
  width: 100%;
  text-align: center;
  max-width: 100%;
}


.diary-parent > .d-flex {
  min-width: 900px;
}



}

@media (min-width:481px) and (max-width: 990px){
  .popup-content.image_modal-content {
    width: 80% !important;
  }

}

@media(max-width: 767px){
  .cm_25 {
    width: 100%;
    margin-right: 0;
}

.cm_75 {
  width: 100%;
}

.calendar_card {
  padding: 10px !important;
}

.cm_mob_block {
  display: block !important;
  margin-top: 15px !important;
}

.cm_mob_block a.btn {
  display: block;
  margin-top: 15px;
}

.top_header h1 {
  text-align: left;
  font-size: 20px;
  padding-left: 40px;
  margin-top: 7px;
}

}

@media (max-width: 480px){
  div#appleid-signin img {
    width: 100%;
    height: 51px;
    object-fit: cover;
}
#appleid-signin {
  border-radius: 4px;
  overflow: hidden;
}
  .cm_search .form-group {
    width: 100%;
}
.my-forms {
  min-width: 100%;
  max-width: 100%;

}
.cm_search {
  margin-bottom: 0px;
}
.cm_sort {
  width: 100%;
  padding-right: 30px;
  margin-top: -20px;
}

.sortingdiv {
  margin-top: 0px;
  width: 100%;
}
  .account-table table tr td {
    padding-left: 10px;
}
  .booking_dateinfo .review_star span {
    font-size: 16px;
  }
  .popup-content.image_modal-content  {
    width: 80% !important;
  }
  .date_info h5 {
    font-size: 12px;
  }
  .booking_dateinfo .date_info h5 {
    text-align: left !important;
  }
  .btn-edit {
    margin-left: 0px;
    margin-right: 20px;
  }
  .review-btn{
    float: none;
  }
  .pad-L0 ,  .pad-R0{
    padding-right: 15px;
    padding-left: 15px;
  }

.cm_box{
  padding: 0px 12px;
}
  .review-btn .btn-edit{
    margin-left: 0px;
    margin-right: 12px;
  }
  .client_review_first .push {
    margin-left: 0;
  }
  .notfound .notfound-404 h1 {
    font-size: 100px !important;
    letter-spacing: 0px !important;
    margin-left: -20px;
}
.home, .home-search {
  margin-left: 0px;
   margin-right: 0px;
}
.cm_team-round-img {
  width: 100px;
  height: 100px;
}
.team-round-img {
  top: 45%;
}
.date_info h6{
  text-align: left !important;
}
.btnsml-screen{
  width:100%;
  margin-top: 12px;
}
.btnsml-screen-center{
  width:100%;
  text-align: center;
  margin-top: 12px;
}
.accountleft p {
  padding: 12px 0px;
}
}

@media(max-width: 767px){
/* ------------react bootstrap modal------------ */
.fade.modal.show {
  padding-right: 0px !important;
}
.menu_open .menu_ovelay {
  position: fixed;
  height: 100%;
  width: calc(100% - 250px);
  right: 0;
  top: 0;
  z-index: 999;
  cursor: pointer;
}

.profile .modal-body {
  padding: 1rem;
}
.modal-open .modal::-webkit-scrollbar,
.modal-open::-webkit-scrollbar {
  width: 0px !important;
  height: 0;
  background-color: #F5F5F5;
}
.popupscroll::-webkit-scrollbar{
  width: 0px !important;
}
body.modal-open {
  position: fixed;
  height: 100%;
  overflow: hidden !important;
}
.popupscroll {
  overflow-y: auto;
  height: calc(100vh - 2px);
  width: 100%;
}
.fade.modal.show {
  padding-left: 0 !important;
}

.calender_popupbg-content , .calender_popupbg{
  background: #DCDCDC !important;
}
/* ------------react bootstrap modal------------ */
  
  .footer-section {
    min-height: calc(100vh - 135px);
}
  .gmap_canvas {
    height: 23vw;
  }
  .cm_search {
    padding-left: 25px;
  }
  .sortingdiv {
    margin-left: 10px;
    float: left !important;
    margin-top: 20px;
  }
.home-box{
  margin: 15px;
}
.my-forms{
  margin-top: 12px;
}
.services-offered tr td:first-child {
    width: 50%;
}
.member_discription{
    text-align: center;
    padding: 0 12px;
}
.services-offered .cm_table {
    margin: 20px 0px;
}
.logo{
  position: absolute;
  right: 1px;
  text-align: right;
  padding-right: 2px;
  }
  .rating-header > span:nth-child(1) {
    font-size: 40px;
  }
  span.Star span {
    font-size: 24px;
  }
  .rating-header > span:nth-child(3) {
    font-size: 25px;
  }
  .mobile_trigger{
    display:block;
  }
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center{
    width: 30px !important;
    height: 30px !important;
    max-width: 30px !important;
    font-size: 14px;
  }
  .navbar-collapse {
    position: fixed;
    width: 250px!important;
    height: calc(100vh - 49px);
    left: -250px;
    top: 49px;
    background: #ffffff;
    text-align: left;
    padding-top: 30px;
    transition: all .3s;
    z-index: 9;
    display: block !important;
  }

  .react-tabs__tab-list::-webkit-scrollbar , .cm_calendar .modal-body::-webkit-scrollbar{
    width: 0px;
    height: 0px;
  }
  .react-tabs__tab-list::-webkit-scrollbar-track , .cm_calendar .modal-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px transparent;
  }
  .react-tabs__tab-list::-webkit-scrollbar-thumb , .cm_calendar .modal-body::-webkit-scrollbar-thumb {
    background-color:transparent;
    outline: 1px solid transparent;
  }

  .menu_open .navbar-collapse {
      left: 0;
   }
.navbar-collapse ul li {
  display: block;
  padding: 0 15px;
  margin: 0 0 20px;
}
.navbar-dark .navbar-nav .nav-link , .navbar-dark .navbar-nav .nav-link:hover{
  color: #000;
}
.phn-name{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.cm-navbar {
  padding: .5rem 1rem;

}
.services-offered th, td {
  padding: 14px 0px ;
  text-align: left;
  font-size: 14px;
}
.client_review{
height: auto !important;
}
.services-offered .cm_table {
margin: 0px 0px 26px;
}
.mT80{
  margin-top:0px
}
.cm_bookin_deatils {
  padding: 30px 28px;
}
.p12 .text-left , .p12 .text-right
{
text-align: left !important;
}
button.view_service_btn {
  padding: 12px 40px 12px 18px;
  margin-top: 20px;
}
.flex-container {
  justify-content: space-around;
  padding: 12px 0px;
}
.my_pro{
  margin-bottom: 20px !important;
 }
 .account_img{
  text-align: center;
  max-width: 140px;
  margin: 0 auto;
  margin-bottom: 25px;
 }
 .hide_smallscreen{
   display: none;
 }
 .nav-item.dropdown .dropdown-menu {
  display: block !important;
}
.cm_bookin_deatils{
  padding:0px;
  }
  ul.booking .react-tabs__tab {
      font-size: 25px;
      width: 100%;
    margin-bottom: 10px;
    text-align: center;
  }
  .react-tabs__tab--selected:after {
      top: 34px;
  }
  .react-tabs__tab--selected:after {
    width: 50%;
    transform: translate(50%, 50%);
  }
/* -----------------------------------css for new phone design--------------------------- */
.cm_bg_home {
  margin: 10px auto;
}
 .photobox {
  justify-content: center;
}
.dropdown-toggle::after{
  display: none;
}
.home-box {
  border-radius: 10px;
    padding: 6px;
    box-shadow: 0px 0px 9px #8888884a;
    margin: 10px -6px;
    min-height: 1px;
}
.sortingdiv p span img {
  width: 18px;
}
.homerating {
  margin-bottom: 8px;
}
.rating-header > span:nth-child(1) {
  font-size: 22px;
}
.rating-header > span:nth-child(3) {
  font-size: 19px;
}
svg.widget-svg {
  width: 20px !important;
  height: 20px !important;
  margin-top: -5px;
}
.homeimge-height {
  height: 216px !important;
}
.p12 {
  padding: 5px 0px;
}
h4.light_text {
  margin-bottom: 3px;
}
.p12 .text-right p {
  font-size: 14px;
  margin-bottom: 0px;
}
.photobox img {
  height: 110px;
  border-radius: 12px;
}
button.view_service_btn {
  padding: 0px 40px 3px 18px;
}
button.view_service_btn p {
  margin-bottom: 0px;
}
.flex-container {
  justify-content: space-around;
  padding: 12px 0px 5px;
}
.searching_web{
  display: none;
}
.phone-search{
  display: block;
}
.search-mob-pad{
  padding: 0px 8px;
}
.searching_mob .form-group {
  width: 100%;
  min-width: 100%;
  margin-bottom: 0px;
}
.searching_mob .form-group .my-forms {
  background: #7C999A;
  margin-top: 8px;
    font-family: Arial, FontAwesome;
    font-weight: 100;
    min-width: 99%;
}
.searching_mob .form-group:first-child .my-forms {
  margin-top: 0px;
}
.search-mob-pad .sortingdiv {
  background: #7C999A;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  margin-top: 8px;
  margin-left:0px;
  width: 99%;
}
.search-mob-pad .sortingdiv p{
  margin-bottom: 0px;
  background: #7C999A;
  padding: 7px 14px;
}

.small_searchbox{
  padding: 0px 8px;
  display: flex;
}
.small_searchbox .search , .small_searchbox .sort{
  background: #7C999A;
    font-size: 14px;
    padding: 10px 6px;
    border-radius: 6px;
    border: none;
    color: #fff;
    text-align: center;
    cursor: pointer;
}
.small_searchbox .search{
  width: calc(100% - 47px);
  margin-right: 2%;
  text-align: left;
  
}
.small_searchbox .sort{
  width: 46px;
  padding: 3px 6px;
}
.small_searchbox .sort img{
  width: 30px;
  height: auto;
}
.search .fa-circle {
  font-size: 5px;
  margin: 0 8px;
}

.autocomplete-dropdown-container {
  display: block;
  width: 100%;
  position: static;
  z-index: 99;
  background: transparent !important;
  color: #fff;
  overflow-wrap: break-word;
}


.suggestion-item,
.suggestion-item--active {
  display: block !important;
  width: 100% !important;
  position: static !important;
  z-index: 99 !important;
  color: #fff !important;
  overflow-wrap: break-word !important;
  font-size: 14px !important;
  padding: 10px 0 !important;
  background: #7c999a !important;
  border-bottom: 1px solid #b1b1b1 !important;
}

.suggestion-item:last-child,
.suggestion-item--active:last-child {
  border-bottom: none !important;
  border-radius: 0px 0px 6px 6px !important;
}

.suggestion-item:first-child,
.suggestion-item--active:first-child {
  border-top: 1px solid #fff !important;
  border-radius: 6px 6px 0px 0px !important;
}

.cm_border_r_none .my-forms {
  border-radius: 6px 6px !important;
  padding-left: 30px;
}

.cm_border_r_none .my-forms:focus {
  outline: none !important;
}

.cm_i {
  position: absolute;
  top: 20px;
  left: 6px;
  color: #fff;
  font-size: 14px;
}

span.autoc_item {
  display: block;
  padding-left: 30px;
}



.dropdown-menu {
  background-color: transparent;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.dropdown-item, .dropdown-item:hover {
  color: #000000;
  text-decoration: none;
  background-color: transparent;
  border-top: 1px solid #eaeaea;
}

.dropdown-item:last-child {
  border-bottom: 1px solid #eaeaea;
}

.nav-item.dropdown .dropdown-menu {
  margin: 0 -15px;
}

}



.sidenav_toggler {
  position: absolute;
  left: -60px;
  top: 0;
  font-size: 22px;
  background: #caebee;
  width: 60px;
  height: 70px;
  line-height: 63px;
  text-align: center;
  cursor: pointer;
}



.booking_card.cm_wrap_text {
  padding: 7px 15px;
  min-height: 30px;
  margin: 0;
}

.booking_card.cm_wrap_text .cm_sdule_time {
  top: 4px;
}

.booking_card.cm_wrap_text h5 {
  font-size: 14px;
  margin: 0;
}


input.form-control.is_readonly {
  background: rgba(230, 230, 230, 0.51);
  border: 1px solid #dee2e6 !important;
}





.img-browse{
  display: none;
}

.full_btn_with .dropdown button {
  width: 100%;
}

.full_btn_with .dropdown {
  width: 50% !important;
  margin-right: 10px;
}

.full_btn_with .dropdown-menu {
  background-color: #fff;
  border-radius: 9px;
  box-shadow: none;
}


.full_btn_with .dropdown-item, .full_btn_with .dropdown-item:hover {
  color: #000000;
  border-bottom: 1px solid #dfdfdf;
}

.full_btn_with  .show>.btn-outline-dark.dropdown-toggle,
.full_btn_with .dropdown button:focus,
.full_btn_with .dropdown button:hover {
  background: transparent !important;
  color: #000 !important;
  box-shadow: none !important;
}

@media (max-width: 575px) and (min-width: 320px){
 
.rwdpDayPickerContainer {
  padding: 25px 15px 0px!important;
}
.rwdpNextWeekArrow, .rwdpPrevWeekArrow {
  border-top: 15px solid transparent !important;
  border-bottom: 15px solid transparent !important;
  top: calc(50% - 12px) !important;
}
.rwdpPrevWeekArrow {
  border-right: 15px solid #061E1E !important;
  left: 0;
}
.timeslot {
  min-height:auto;
}
.rwdpNextWeekArrow {
  border-left: 15px solid #061E1E !important;
  right: 0;
}
.rwdpDayBoxDesktop .rwdp-DayCircle-container {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
  width: 35px !important;
  height: 35px !important;
  max-width: 35px !important;
  min-width: 35px;
  min-height: 35px;
  margin: 0px 2px 0px 2px;
  font-size: 10px;
  font-weight: 600;
}
svg.widget-svg {
  width: 24px !important;
  height: 24px !important;
  transition: transform 0.2s ease-in-out 0s;
}
/* ------------------making weakdY ON TOP---------- */
.rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center strong , .gray-holiday strong {
  position: absolute;
  top: -68%;
}
/* .timeslot ul {
  flex-wrap: nowrap;
  overflow: auto;
  width: 100%;
} */
.timeslot ul li, .timeslot ul li a {
  font-size: 12px;
  background: #91DEDD;
  color: #061E1E;
  width: 55px !important;
  border-radius: 8px;
  margin: 9px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding: 6px 10px;
}
}

@media (max-width: 1199px) and (min-width: 991px){
  .popup-content.image_modal-content{
    width: 50% !important;
  }
}
@media (max-width:990px) and (min-width:768px){
  /* .gmap_canvas {
    height: 25vw;
  } */
  .account-table table tr td {
    padding-left: 10px;
}
.cm_team-round-img {
  width: 124px;
  height: 124px;
}

.btn-edit {
   margin-left: 0px;
}
}
@media (max-width:991px) and (min-width:767px){
  .home, .home-search{
    margin-left: 0px;
    margin-right: 0px;
  }

  .p12 .text-left , .p12 .text-right
{
text-align: left !important;
}
button.view_service_btn {
  padding: 12px 40px 12px 18px;
}
.flex-container {
  justify-content: space-around;
  padding: 12px 0px;
}
}
@media (min-width:1200px) and (max-width:1280px){
  .view_service_btn {
    font-size: 13px;
    min-width: 121px;
    padding-right: 24px;
}
.view_service_btn .fa-angle-right {
  font-size: 21px;
  position: absolute;
  right: 4%;
}
}
@media (max-width: 1673px) and (min-width: 1200px)
{
.editmyaccount-content {
    width: 53% !important;
}
}
@media (max-width: 1199px) and (min-width: 991px){
.editmyaccount-content {
    width: 80% !important;
}
}

@media (max-width: 1332px) and (min-width: 1200px){
  ul.booking .react-tabs__tab {
    font-size: 24px;
}

}
@media (max-width: 1480px) and (min-width: 1333px){
  ul.booking .react-tabs__tab {
    font-size: 30px;
    
}
}

@media (max-width: 1530px) and (min-width: 1481px){
  ul.booking .react-tabs__tab {
    font-size: 31px;
    
}

}

@media (min-width:766px){
  .cm_modal {
    overflow: auto;
    /* max-height: calc(100vh - 80px); */
    max-height: 100vh;
  }
}
@media (min-width:576px) and (max-width:767px){
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink {
    margin-top: -14px;
}
}
@media (max-width:575px){
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center.pink {
    margin-top: -10px;
  }
  .hideBigscreen{
    display: block;
    margin-top: 20px;
    text-align: center;
}
  .hideSmallscreen{
    display: none;
  }
}
.contentBg body{
  background: white;
}
@media (max-width:767px){
  .image-gallery-thumbnail {
    width: 105px;
}
}

@media (max-width:767px){
  .react-datepicker-wrapper {
    min-width: 100%;
  }
  .react-datepicker__input-container{
    min-width: 100%;
  }

  .mobile_trigger .fa-bars {
    font-size: 32px;
}

.col-sm-4.order-sm-2 {
  position: static;
}


.cm_pos_relative_ {
  position: relative;
  padding-top: 15px;
}
}
@media (min-width:1010px) and (max-width:1068px){
  .cm_search{
     padding-right: 15px;
  }
}
@media  (min-width:10px) and (max-width:1024px){
  .my-forms {
    min-width: 148px;
    max-width: 148px;
  }
}
/* @media all and (orientation:portrait) {

  } */



  .calendar_card {
    box-shadow: 5px 5px 6px rgba(0,0,0,0.24);
    background: #fff;
    max-width: 600px;
    margin: 0 auto;
    padding: 15px 30px;
    border-radius: 9px;
    text-align: center;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    top: 10px;
    z-index: 9999;
}

.calendar_card p {
  border-radius: 8px;
  width: 50px;
  height: 50px;
  margin: 0;
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
  padding-top: 4px;
  cursor: pointer;
  position: relative;
}

.current_date p {
  background: #BCEBEA;
}

.calendar_card h4 {
  min-width: 125px;
}

p.cm_gary {
  opacity: 0.3;
}

.calendar_card p:after {
  content: '';
  width: 6px;
  height: 6px;
  background: #061E1E;
  position: absolute;
  bottom: 9px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100%;
}

.cm_holiday p {
  background: #efefef;
}

.calendar_card i {
  cursor: pointer;
}

.cm_weekend p {
  background: #e6ffff;
}

 .diary-parent {
  position: relative;
  padding-top: 190px;
  padding-left: 65px;
}

.times_no {
  width: 65px;
  text-align: right;
}


.times_no > div {
  min-height: 180px;
  padding: 0 15px;
  position: relative;
  top: -13px;
}

.cm_width_25 .cm_body,
.cm_width_25 .cm_footer {
  max-width: 270px;
  display: inline-block;
  width: 100%;
}

.cm_width_25 {
  text-align: right;
}

/* .cm_line_w {
  width: calc(100vw - 187px);
  height: 1px;
  background: #707070;
  position: absolute;
  bottom: -13px;
  left: 65px;
} */

/* .nav_menu_open .cm_line_w {
  width: calc(100vw - 127px);
} */


.body_container.cm_bg_white {
  background: #fff;
  min-height: calc(100vh - 68px);
}

.booking_card {
  background: #D6D5EC;
  margin-bottom: 5px;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  z-index: 9;
  min-height: 45px;
}

.booking_card_wrapper {
  padding: 0 15px;
  background-size: 58px;
}

.booking_card_wrapper > .d-flex {
  position: relative;
}

.cm__col {
  position: relative;
  width: calc(100vw / 3 - 90px);
  background-image: url('./assets/images/notpad_bg.png');
  background-size: 60px;
}

table.daily-schedule thead {
  display: none !important;
}

.diary-parent > .d-flex:before {
  content: '';
  position: absolute;
  width: 65px;
  height: 100%;
  background: white;
  left: 0;
  top: 20px;
}

.booking_card_wrap {
  height: 175px;
  padding: 5px;
}

.cm__border {
  border-right: 1px solid #707070;
}
.booking_card img {
  position: absolute;
  right: 14px;
  top: 15px;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.booking_card h5 {
  padding-right: 50px;
}

.booking_card.cm_wrap_text h5 {
  display: inline-block;
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.cm_sdule_time {
  position: absolute;
  right: 15px;
  bottom: 10px;
  margin: 0;
  font-weight: 500;
}

.cm__boxs {
  border: 1px solid #b0b3b5;
  border-radius: 5px;
}

.cm__box h5 {
  padding: 15px;
  margin: 0;
  border-bottom: 1px solid #ddd;
  font-size: 28px;
  font-weight: 300;

}

.cm__box table {
  width: 100%;
}

.i_width {
  width: 53px;
}
.i_width svg {
  cursor: pointer;
  width: 21px;
}

.i_width .fa-check-circle{
  font-size: 23px;
  color: #061e1e;
}

ul.walk_client {
  margin: 0 -30px;
  padding: 0;
  list-style: none;
}

ul.walk_client li {
  border-bottom: 1px solid rgba(112, 112, 112, 0.1);
  padding: 15px;
  padding-left: 75px;
  position: relative;
}

ul.walk_client li p {
  margin: 0;
}

ul.walk_client li:hover {
  background: #dff1f4;
  cursor: pointer;
}

.client_checkout .tab-pane {
  padding: 0 15px;
}

li.add_client_btn {
  background: rgba(191, 228, 234, 0.5);
  padding: 14px !important;
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
}

ul.walk_client li b {
  position: absolute;
  left: 23px;
  top: 42%;
  font-size: 44px;
  text-transform: lowercase;
  transform: translateY(-50%);
  line-height: 1;
}

li.add_client_btn svg {
  display: inline-block;
  width: 18px;
  margin-right: 6px;
}

li.add_client_btn svg rect {
  fill: #000;
}

.client_search .form-control {
  border-radius: 10px;
  min-width: 380px;
}

.client_table {
  box-shadow: 0 3px 6px rgba(0,0,0,0.31);
  background: #fff;
  margin-top: 30px;
  border-radius: 5px;
  padding: 5px 0;
}

.client_table th {
  border: 0 !important;
}

.client_table img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.cm_client_val,
.client_detail_wrapper .accordion {
  margin-left: -30px;
  margin-right: -30px;
}

.client_detail_wrapper .card {
  border-radius: 0;
}

.cm_client_val {
  border-top: 1px solid #e8e8e8;
  padding: 15px;
  margin-top: 30px;
}

.cm_client_val > div {
  padding: 0 20px;
  text-align: center;
}

ul.some_detail {
  margin: 0 -30px;
  padding: 0;
}

ul.some_detail li {
  border-bottom: 1px solid #e8e8e8;
  padding: 8px 15px;
  position: relative;
}

ul.some_detail li:last-child {
  border-bottom: 0;
}

ul.some_detail li p {
  margin: 0;
}

li.cm_bg_date > div {
  position: absolute;
  background: #DFF1F4;
  left: 0;
  top: 0;
  height: 100%;
  padding: 5px 10px;
}

li.cm_bg_date {
  padding-left: 60px !important;
}

.about_hairdresser {
  position: absolute;
  width: calc(100% - 100px);
  background: #BCEBEA;
  top: -90px;
  border-radius: 9px;
  box-shadow: 5px 5px 6px rgba(0,0,0,0.2);
  padding: 9px 15px;
  padding-right: 75px;
  left: 50%;
  transform: translateX(-50%);

}

.about_hairdresser.cm_dark_b {
  background: #061E1E;
  color: #fff;
}

.cm_dark_b .text_red {
  color: #fff !important;
}

.about_hairdresser img {
  position: absolute;
  right: 14px;
  top: 11px;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  object-fit: cover;
  background: #fff;
}


.about_hairdresser h3 {
  font-size: 22px;
  margin: 0;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.invoice_card_wrapper {
  box-shadow: 0px 3px 6px rgba(0,0,0,0.31);
  border-radius: 9px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}

.cm_head {
  background: #BFE4EA;
  margin-bottom: 15px;
  border-radius: 10px 10px 0 0;
  padding: 15px;
  text-align: center;
}

.cm_body ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
  margin-top: 175px;
}

.cm_body ul li {
  font-size: 20px;
  font-weight: 300;
  border-bottom: 1px solid rgba(6, 30, 30, 0.5);
  padding: 10px;
}

.cm_footer {
  margin-top: 5px;
  padding: 20px 10px;
  text-align: right;
  border-top: 1px solid rgba(6, 30, 30, 0.5);
  padding-bottom: 70px;
}

.stap_wrapper ._img_wrap {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(25%, 0, 0);
    transform: translate3d(25%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.stap_wrapper ._img_wrap {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

._img_wrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;
  width: 100%;
}

.btn-outline-dark.focus, .btn-outline-dark:focus,
.btn-dark.focus, .btn-dark:focus {
  box-shadow: none;
}

.cm_hide_mod {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 20px;
  z-index: 9999;
  padding: 15px 20px;
}


@media(max-width: 1300px){
  .cm__col {
    width: calc(100vw / 2 - 90px);
  }
}

@media(max-width: 991px){




.cm__col {
  width: calc(100vw - 180px);
}
}

@media(max-width: 767px){
  .calendar_card {
    position: static;
    transform: none;
  }

  .diary-parent {
    padding-top: 120px;
}

.cm__col {
  width: calc(100vw - 156px);
}

.cm_staff_catd {
  min-width: 215px;
}

.cm_table_input {
  width: 60px;
}

.setting_page .nav-tabs .nav-link, .staff_page .nav-tabs .nav-link {
  margin-right: 10px !important;
  min-width: 100px !important;
}

.body_container.cm_bg_white {
  padding-top: 25px;
}

}

@media(max-width: 576px){
  .booking_form_wrapper {
    padding: 15px;
  }

  .cm__box h5 {
    font-size: 20px;
  }

  .cm__box table {
    min-width: 300px;
  }

  .i_width {
    text-align: center;
  }

  .calendar_card .d-flex.justify-content-between {
    overflow: auto;
}


.new-booking-tab {
  padding-left: 15px;
  padding-right: 15px;
}

.cm_picker_wrap {
  position: relative;
  padding: 0 15px;
}

.cm__col {
  width: calc(100vw - 80px);
}

.top_header {
  padding: .8rem 10px;
}

.user_pic {
  right: 10px !important;
}

.user_pic img {
  width: 35px !important;
  height: 35px !important;
  margin-left: 10px !important;
}

.user_pic .btn {
  font-size: 12px;
}



.top_header h1 {
  margin-top: 0px;
}

.top_header .menu_toogle_icon {
  top: 13px;
}

.cm_client_val {
  display: block !important;
}

.cm_client_val > div {
  padding: 0 !important;
  margin-bottom: 15px;
}

.cm_main_col_3 i {
  right: 2px;
}

}


.staff_page .tab-pane {
  padding: 15px;
  box-shadow: 1px 3px 6px rgba(0,0,0,0.31);
  position: relative;
  min-height: calc(100vh - 170px);
}

.staff_page .nav-tabs {
  border-bottom: 0;
}

.setting_page .nav-tabs .nav-link,
.staff_page .nav-tabs .nav-link {
  border: 0;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  background: #F7F7F7;
  margin-right: 30px;
  min-width: 200px;
  text-align: left;
  padding: 15px;
  outline: none;
}

.setting_page .nav-item.nav-link.active,
.staff_page .nav-item.nav-link.active {
  background: #BCEBEA;
}

.table_staf_member {
  padding: 15px 0 !important;
}

.top_right {
  position: absolute;
  right: 0px;
  top: -50px;
  min-width: 150px;
}

.table_staf_member td img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
}

.staff_page .table td, .staff_page .table th {
  vertical-align: middle;
}

.user_pic {
  position: absolute;
  right: 40px;
  top: 9px;
}

.user_pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  margin-left: 20px;
}

.add_staff_form {
  box-shadow: 0 3px 6px rgba(0,0,0,0.31);
  padding: 20px;
  margin: 20px 0;
  border-radius: 8px;
}

.file_upload {
  width: 96px;
  height: 96px;
  position: relative;
  background: #F7F7F7;
  border-radius: 100%;
}

.file_upload input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
  font-size: 0;
}

.file_upload i {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #000;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  text-align: center;
  line-height: 24px;
  background: #fff;
}

.file_upload img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}

.btn-pink {
  background: #FEAFBC;
  color: #000;
}

.cm_hours_popup .modal-header {
  display: block !important;
}

.cm_hours_popup .modal-header p {
  margin: 0;
}

.cm_hours_popup button.close {
  position: absolute;
  right: 30px;
  top: 24px;
  font-size: 35px;
}
.cm_succ_msg, .account-table span .cm_succ_msg{
  color: green;
  font-size: 14px;
  margin-left: 4px;
  /* padding-bottom: 10px; */
  text-decoration: none !important;
}


.daily-schedule td {
  padding: 0 1.5rem !important;
  /* line-height: 1;
  height: 28px;
  min-height: 1px !important;
  display: inline-block; */
}


button.btn.btn-primary.btn-block.mb-3 {
  position: relative;
}

span.cm_angle {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  margin-left: 10px;
  position: absolute;
  right: 20px;
  top: calc(50% - 4px);
  transform: translateY(-50%) rotate(45deg);
}


.daily-schedule td.daily-schedule-bucket-container,
.daily-schedule td.daily-schedule-bucket-container .daily-schedule-bucket {
  padding: 0 !important;
  position: relative;
}

.bg_white_modal .modal-dialog {
  background: #fff !important;
  border-radius: 9px;
}

.nav_menu_open .sidebar_tog_icon_open,
.sidebar_tog_icon_close {
  display: none;
}

.nav_menu_open .sidebar_tog_icon_close {
  display: block;
}

span.menu_icon svg {
  width: 25px;
}

.daily-schedule-bucket button {
  position: absolute;
  width: 100%;
  left: -10px;
  top: -14px;
  height: 31px;
  opacity: 0;
  cursor: pointer;
}


@media(max-width: 1100px){
  .times_no {
    left: 7px;
}


.nav_menu_open .sidebar_tog_icon_close {
  display: none;
}

.nav_menu_open .sidebar_tog_icon_open,
.sidebar_tog_icon_close {
  display: block;
}
}

@media(max-width: 767px){
  .times_no {
    top: 340px;
}
}

.address_input_wrap.disabled input {
  background-color: #e9ecef;
  cursor: default;
  user-select: none;
  pointer-events: none;
}

.darkblue .rwdp-DayCircle {
  background: #061e1e !important;
  border-color: #061e1e !important;
  color: #91dedd !important;
}

#appleid-signin {
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}


@media(max-width: 420px){
  .rwdp-DayCircle.rwdp-flex-box.rwdp-flex-column.rwdp-justify-content-center.rwdp-align-items-center {
    width: 30px !important;
    height: 30px !important;
    max-width: 30px !important;
    min-width: 30px;
    min-height: 30px;
}
}